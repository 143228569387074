import React, { Component } from 'react';
import { Card, Form, Button, ListGroup, Badge } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import BatchContext from './BatchContext';

class BatchEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      op: '',
      opValue: '',
      operations: []
    };
  }


  handleSelect = (e) => {
    this.setState({ op: e.target.value });
  }

  handleUpdate = (e) => {
    this.setState({ opValue: e.target.value });
  }

  addBatchOperation = () => {
    const { op, opValue } = this.state;
    if (op && opValue) {
      this.setState(prevState => ({
        operations: [...prevState.operations, { title: op, value: opValue }],
        op: '',
        opValue: ''
      }));
    }
  }

  removeBatchOperation = (idx) => {
    this.setState(prevState => ({
      operations: prevState.operations.filter((_, i) => i !== idx)
    }));
  }

  render() {
    const { checkedCount, totalCount, onCheckAll, getBatchDataPaths } = this.props;
    const { op, opValue, operations } = this.state;

    return (
      <Card className={`bulk-edit ${checkedCount > 0 ? 'd-block' : 'd-none'}`}>
        <Card.Header><strong>Bulk Editing</strong></Card.Header>
        <Card.Body>
          <Form.Group className="text-center">
            <Form.Label className="font-weight-bold">{checkedCount} rows selected</Form.Label>
            <Form.Check 
              type="checkbox"
              onChange={() => onCheckAll(true)} 
              label={`Select all ${totalCount} matching rows`} />
          </Form.Group>                      
          <Form.Group>
            <Form.Label>Batch Operation</Form.Label>
            <Form.Control
              as="select"
              name="op"
              value={op}
              onChange={this.handleSelect}>
                <option value="">Select Operation</option>
                <option value="Add Tag">Add Tag</option>
                <option value="Remove Tag">Remove Tag</option>
                <option value="Set Vendor">Set Vendor</option>
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="text"
              name="opValue"
              value={opValue}
              onChange={this.handleUpdate}>
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Button 
              block
              size="sm"
              variant="outline-info"
              onClick={this.addBatchOperation}>
                Add Bulk Operation
            </Button>
          </Form.Group>
        </Card.Body>
        <Card.Footer>
          <small>
            Planned Operations: {operations.length}
          </small>
        </Card.Footer>

        <ListGroup variant="flush">
          {operations.map((operation, idx) =>
            <ListGroup.Item key={idx} className="d-flex justify-content-between">
              <span>
                {idx + 1}. {operation.title} 
                <Badge size="sm" variant="secondary" className="ml-2">{operation.value}</Badge>
              </span>
              <span>
                <FaTimes 
                  className="clickable" 
                  onClick={() => this.removeBatchOperation(idx)} />
              </span>
            </ListGroup.Item>
          )}
        </ListGroup>

        {operations.length > 0 &&
          <Card.Body>
            <BatchContext.Consumer>
              {batch => (
                <Button 
                  block
                  size="sm"
                  variant="info"
                  onClick={() => batch.addBatch({
                    dataPaths: getBatchDataPaths(),
                    onData: operations
                  })}>
                    Begin Bulk Updates
                </Button>
              )}
            </BatchContext.Consumer>
          </Card.Body>
        }
      </Card>
    );
  }
}

export default BatchEdit;
