import React from 'react';
import ListingWidget from './ListingWidget';
import { EditComponent, TextDisplay, getConditionVariant } from './AllThing';
import { setPath } from './fire';
import { Alert, Container, Row, Col, Form, FormControl, Spinner, Button, Tabs, Tab, Card, Badge } from 'react-bootstrap';
import { FaBarcode, FaBuilding, FaChevronDown, FaChevronRight, FaFolderOpen, FaHashtag, FaImages, FaMagic, FaMapMarkedAlt, FaPencilAlt, FaPuzzlePiece, FaTimes, FaTruck, FaRegFile, FaRuler, FaWeight } from 'react-icons/fa';
import { getProductCost, getProductPrice, getProductProfit, getProductFloor, getProductCeiling } from './repricingUtils';
import { QuickEdit } from './QuickEdit';
import { Slideshow } from './Slideshow';
import { BiArrowToBottom, BiArrowToTop } from 'react-icons/bi';
import { CiReceipt } from 'react-icons/ci';
import { RiPriceTag3Line } from "react-icons/ri";
import { TbMoneybag } from "react-icons/tb";

class ProductEdit extends EditComponent {
  constructor(props) {
    super(props, { image: [] });    
    this.saveFields = [ 
      'key', 'vendor', 'model', 'upc', 
      'title', 'desc', 'image', 'partNumber',
      'bullets', 'category', 'productURL',
      'dimensions', 'reprice'
    ];
    
    // Configure data connector to allow new records
    this.allowNewRecord = true;    
    
    // Defaults
    this.state.row.upc = this.state.row.upc || "";
    this.state.row.partNumber = this.state.row.partNumber || "";
    this.state.row.category = this.state.row.category || "";
    this.state.row.dimensions = this.state.row.dimensions || {
      width: '', depth: '', height: '', weight: ''
    };
    this.state.row.updateInstructions = this.state.row.updateInstructions || "";
    
    // Bindings
    this.removeImage = this.removeImage.bind(this);
    this.updateImages = this.updateImages.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    
    // Ensure array on image field
    if (this.state.row.image && !this.state.row.image.length) {
      this.state.row.image = Object.values(this.state.row.image);
    }
  }
  
  beforeSave(row) {
    if (!row.key) {
      row.created = new Date().toJSON();
    }
    
    return Promise.resolve(row);
  }
  
  removeImage(id) {
    this.setState(s => ({ row: {
      ...s.row, 
      image: s.row.image.filter((url, idx) => idx !== id)
    }}));
  }
  
  async updateImages(event) {
    const row = this.state.row;
    let imageOptions = row.imageOptions;
    
    if (!imageOptions || !imageOptions.length) {
      imageOptions = await this.generate('images');
    }
  
    this.showImages(imageOptions, {
      selected: row.image,
      add: true, 
      remove: true, next: true, prev: true,
      select: this.selectImage,
      buttons: [
        { value: 'Save', callback: this.selectImages }
      ]
    });
  }

  saveProductReprice(event, type) {
    const { name, value } = event.target;
    const pid = this.state.row.key;

    const formattedValue = value.length ? parseFloat(value) : value;
    setPath(`/product/${pid}/${name.split('.').join('/')}`, formattedValue);
  }  

  handleUpdate() {
    this.generate('updateProduct');
  }
  
  render() {
    const row = this.state.row;    
    const images = row.image || '';
    const bullets = row.bullets || [ ];
    
    return (
      <form autoComplete="off">
        { this.renderModal({ centered: true, dialogClassName: 'modal-sub' }) }
        { row.notes && row.notes.length &&
          <Alert variant="warning">
            <Alert.Heading>Important Product Notes</Alert.Heading>              
            <p>{ row.notes }</p>
          </Alert>
        }
        
      <Container fluid>

        <Tabs defaultActiveKey="details" id="product-details">
          <Tab eventKey="details" title="Product Details">

            <Row className="product-detail">
              <Col sm={ 6 }>
                { images &&
                  <Slideshow 
                    embed
                    images={ images }
                    maxHeight={ 250 }
                    show={ this.showImages } />
                }
              </Col>
              
              <Col sm={ 6 }>     
                    
                <Form.Group>
                  <Form.Label className="product-title">      
                    <QuickEdit as="textarea" size="sm" rows={3} htmlSize={40}
                      name="title"
                      value={ row.title }
                      onChange={ this.updateRow }>
                        <span className="clickable">
                          { row.title || 'Add Title' }
                          <FaPencilAlt />                        
                        </span>
                    </QuickEdit>
                  </Form.Label>
                </Form.Group>
                
                <QuickEdit as="input" size="sm" htmlSize={20}              
                  name="vendor"
                  value={ row.vendor }
                  onChange={ this.updateRow }>
                    <span className="section">
                      <FaBuilding />
                      <span>{ row.vendor || 'Add Manufacturer' }</span>
                    </span>
                </QuickEdit>
                
                <QuickEdit as="input" size="sm" htmlSize={20}              
                  name="model"
                  value={ row.model }
                  onChange={ this.updateRow }>
                    <span className="section">
                      <FaPuzzlePiece />
                      <span>{ row.model || 'Add Model' }</span>
                    </span>
                </QuickEdit>

                <QuickEdit as="input" size="sm" htmlSize={20}              
                  name="partNumber"
                  value={ row.partNumber }
                  onChange={ this.updateRow }>
                    <span className="section">
                      <FaHashtag />
                      <span>{ row.partNumber || 'Add Part Number' }</span>
                    </span>
                </QuickEdit>

                <QuickEdit as="input" size="sm" htmlSize={20}              
                  name="category"
                  value={ row.category }
                  onChange={ this.updateRow }>
                    <span className="section">
                      <FaFolderOpen />
                      <span>{ row.category || 'Add Category' }</span>
                    </span>
                </QuickEdit>
              
                <QuickEdit as="input" size="sm" htmlSize={20}              
                  name="upc"
                  value={ row.upc }
                  onChange={ this.updateRow }>
                    <span className="section">
                      <FaBarcode />
                      <span>{ row.upc || 'Add UPC' }</span>
                    </span>
                </QuickEdit>

                <QuickEdit as="input" size="sm" htmlSize={4}              
                  name="dimensions.width"
                  value={ row.dimensions.width }
                  onChange={ this.updateRow }>
                    <span className="section narrow">
                      <FaRuler />
                      <span>
                        { row.dimensions.width ? 
                          row.dimensions.width + '"' : 'Width (in)' 
                        }
                      </span>
                    </span>
                </QuickEdit>
                
                <span className="section-divider narrow">
                  <span>x</span>
                </span>

                <QuickEdit as="input" size="sm" htmlSize={4}              
                  name="dimensions.depth"
                  value={ row.dimensions.depth }
                  onChange={ this.updateRow }>
                    <span className="section narrow">
                      <span>
                        { row.dimensions.depth ? 
                          row.dimensions.depth + '"' : 'Depth (in)' 
                        }
                      </span>
                    </span>
                </QuickEdit>
                
                <span className="section-divider narrow">
                  <span>x</span>
                </span>

                <QuickEdit as="input" size="sm" htmlSize={4}              
                  name="dimensions.height"
                  value={ row.dimensions.height }
                  onChange={ this.updateRow }>
                    <span className="section">
                      <span>
                        { row.dimensions.height ? 
                          row.dimensions.height + '"' : 'Height (in)' 
                        }
                      </span>
                    </span>
                </QuickEdit>
                
                <QuickEdit as="input" size="sm" htmlSize={4}              
                  name="dimensions.weight"
                  value={ row.dimensions.weight }
                  onChange={ this.updateRow }>
                    <span className="section">
                      <FaWeight />              
                      <span>
                        { row.dimensions.weight ? 
                          row.dimensions.weight + ' lbs' : 'Add Weight (lbs)' 
                        }
                      </span>
                    </span>
                </QuickEdit>                            
                
                <QuickEdit as="input" size="sm" htmlSize={20}              
                  name="productURL"
                  value={ row.productURL }
                  onChange={ this.updateRow }>
                    <span className="section">
                      <FaMapMarkedAlt />
                      <span>
                        { row.productURL ? 
                          'Edit Product URL' : 'Add Product URL' 
                        }
                      </span>
                    </span>
                </QuickEdit>

                <span 
                  className="section nowrap" 
                  onClick={ this.updateImages }>
                    { !this.state.generating &&
                      <FaImages />
                    }
                    { !!this.state.generating &&
                      <Spinner 
                        className="icon"
                        animation="border" 
                        variant="secondary" 
                        size="sm" />
                    }
                    <span>Update Images</span>
                </span>
              
                <span 
                  className="section nowrap" 
                  onClick={ () => this.generate('product') }>
                    { !this.state.generating &&
                      <FaMagic />
                    }
                    { !!this.state.generating &&
                      <Spinner 
                        className="icon"
                        animation="border" 
                        variant="secondary" 
                        size="sm" />
                    }
                    <span>Generate Product Details</span>
                </span>

                {/* Update Instructions Section */}
                <div className="section mt-1">
                  { !this.state.generating &&
                      <FaMagic />
                  }
                  { !!this.state.generating &&
                      <Spinner 
                        className="icon"
                        animation="border" 
                        variant="secondary" 
                        size="sm" />
                  }
                  <span 
                    className="clickable" 
                    onClick={() => this.setState(prev => ({
                      showUpdateInstructions: !prev.showUpdateInstructions
                    }))}
                  >
                    AI Update Instructions
                    {this.state.showUpdateInstructions 
                      ? <FaChevronDown className="ms-2"/> 
                      : <FaChevronRight className="ms-2"/>
                    }
                  </span>
                  {this.state.showUpdateInstructions && (
                    <>
                      <FormControl
                        as="textarea"
                        rows={3}
                        name="updateInstructions" 
                        className="mt-2 w-100"
                        value={row.updateInstructions}
                        onChange={this.updateRow}
                        placeholder="Enter update instructions here..."
                      />
                      <Button 
                        variant="primary" 
                        className="mt-2 float-right" 
                        size="sm"
                        onClick={this.handleUpdate}>
                        Update
                      </Button>
                    </>
                  )}
                </div>              
                            
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <div className="mb-3 fw-bold">Description:</div>
                
                <QuickEdit as="textarea" size="sm" rows={3} htmlSize={20}
                  name="desc"
                  onChange={ this.updateRow }
                  value={ row.desc }>
                    <span>
                    <div className="mb-3 clickable">                   
                      { row.desc ? 
                        <TextDisplay text={ row.desc } /> : 
                        'Add Description' 
                      }
                    </div>
                    </span>
                </QuickEdit>
                              
                <div className="mb-3 fw-bold">Bullets:</div>
                <ul>
                  { bullets.map((bullet, idx) => 
                  
                      <QuickEdit as="input" size="sm" htmlSize={20}
                        key={ idx }
                        name={ `bullets.${ idx }` }
                        value={ bullet }
                        onChange={ this.updateRow }>
                          <li className="clickable">
                            { bullet }
                          </li> 
                      </QuickEdit>
                      
                  )}
                </ul>
              </Col>  
            </Row>

          </Tab>
          <Tab eventKey="pricing" title="Pricing">
            <Row className="product-detail"> 

              {['new', 'ref', 'use'].map(type => (
                <Col sm={12} md={6}>

                  <Card
                    text={getConditionVariant(type)}
                    className="mb-2"
                  >
                    <Card.Header>
                      <small className="mr-2">
                        <strong>{ type.toUpperCase() }</strong>
                      </small>
                      <CiReceipt className="mr-1" />
                      <small className="mr-2">${ getProductCost(row, type) || 'NA' }</small>
                      <RiPriceTag3Line className="mr-1" />
                      <small className="mr-2">${ getProductPrice(row, type) || 'NA' }</small>
                      <TbMoneybag className="mr-1" />
                      <small className="mr-2">${ getProductProfit(row, type) || 'NA' }</small>
                    </Card.Header>
                    <Card.Body className="text-small">
                      {Object.entries(row.listing || {})
                        .filter(([_, listing]) => listing.condition === type)
                        .map(([listingId, listing]) => (
                          <ListingWidget
                            listingId={listingId}
                            productListing={listing}
                            product={row}
                            editListing={this.editListing}
                          />
                        ))}
                    </Card.Body>
                    <Card.Footer>

                      <QuickEdit as="input" size="sm" htmlSize={3}              
                        name={ `reprice.${ type }.floor` }
                        value={ row?.reprice?.[type]?.floor || '' }
                        onEndEdit={ e => this.saveProductReprice(e, type) }
                        onChange={ this.updateRow }>
                        <span className="mr-2">
                          <BiArrowToBottom className="mr-1" />
                          <small className={`editable ${row?.reprice?.[type]?.floor ? 'text-primary' : ''}`}>
                            ${ getProductFloor(row, type) || 'NA' }
                          </small>
                        </span>
                      </QuickEdit>

                      <QuickEdit as="input" size="sm" htmlSize={3}              
                        name={ `reprice.${ type }.ceiling` }
                        value={ row?.reprice?.[type]?.ceiling || '' }
                        onEndEdit={ e => this.saveProductReprice(e, type) }
                        onChange={ this.updateRow }>
                        <span className="mr-2">
                          <BiArrowToTop className="mr-1" />
                          <small className={`editable ${row?.reprice?.[type]?.ceiling ? 'text-primary' : ''}`}>
                            ${ getProductCeiling(row, type) || 'NA' }
                          </small>
                        </span>                            
                      </QuickEdit>

                    </Card.Footer>
                  </Card>                  

                </Col>
              ))}
            </Row>
          </Tab>
        </Tabs>

      </Container>
      
    </form>
      
    );
  }
}

export { ProductEdit };
