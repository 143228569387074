import React from 'react';
import { RowComponent, formatAmount, calculateAge, getListing } from './AllThing';
import { Form, Image, Popover, Tooltip, OverlayTrigger, Container, Row, Col } from 'react-bootstrap';
import { StockWarehouse } from './StockWarehouse';
import ProductListingsWidget from './ProductListingsWidget.js';

import { WiSunrise } from 'react-icons/wi';

import {
  FaMoneyBillWave,
  FaCopy,
  FaCartPlus,
  FaHashtag,
  FaBarcode,
  FaExclamationTriangle,
  FaDollarSign,
  FaThermometerEmpty,
  FaRegCalendarTimes
} from 'react-icons/fa';

class ProductRow extends RowComponent {
  constructor(props) {
    super(props);    
    
    // Initialize state defaults
    this.state.listings = {};
    this.state.condition = props.defaultCondition || 'new';
    this.state.channel = props.defaultChannel || 'Amazon US';
    this.state.duplicates = [];
    this.state.orders = [];
    
    // onData callback for side-loading listing data
    this.onData(data => {    
      this.populateListings(data);
      this.checkDuplicate(data);
      this.checkOrders(data);
    });    
  }
  
  async checkDuplicate(product) {
    const results = await this.search.simpleQuery('product', `model:"${product.model}"`);
    this.setStateSafe({ duplicates: results });
  }
  
  async checkOrders(product) {
    const to = new Date();
    const from = new Date(to.getTime() - 1000 * 60 * 60 * 24 * 30);
    
    const results = await this.search.simpleQuery(
      'order_purchase_item',
      `
        (manifest.pid:"${ product.key }") 
        AND (NOT _exists_:manifest.committed)
        AND (NOT _exists_:history.returned) 
        AND (NOT _exists_:history.cancelled) 
        AND (history.paid:[${ from.toISOString() } TO ${ to.toISOString() }])
      `
    );

    this.setStateSafe({ orders: results });
  }

  async populateListings(product) {
    this.clearDataRefs('listings');
    
    const listingPromises = Object.keys(product.listing || {}).map(id =>
      new Promise((resolve, reject) => 
        getListing(id, snap => {
          this.addDataRef(snap.ref, 'listings');
          resolve({ [snap.key]: snap.val() });
        })
      )
    );

    const listingsArray = await Promise.all(listingPromises);
    const listings = listingsArray.reduce((acc, listing) => ({ ...acc, ...listing }), {});
    this.setStateSafe({ listings });
  }
  
  render() {
    const { condition, listings, row, orders } = this.state;
    const { editListing, handleCheck, isChecked } = this.props;
    const variant = row.variant || {};

    // Tabulate the ordered but yet received inventory
    const orderedQty = orders.reduce((acc, val) => {
      let qty = 0;
      
      (val.manifest || [ ]).forEach(manifest => {
        if (manifest.type == "order") {
          qty += manifest.quantity;
        }
        
        if (manifest.condition === 'order'
          || manifest.condition === 'parts'
          || manifest.condition === 'return') {
            qty -= manifest.quantity;
        }
      });
      
      return acc + qty
    }, 0);
    
    return (
      <Container fluid className="product-row py-1 mt-2 border-bottom">
        <Row className="align-items-center">
          <Col xs={12} md={4}>
            <div>
              <div
                className="d-flex align-items-center justify-content-center float-left mr-3"
                style={{ width: '75px', height: '75px' }}
              >
                {this.props.bulkEdit && (
                    <Form.Check
                      checked={isChecked(row.key)}
                      type="checkbox"
                      onChange={() => handleCheck(row.key)}
                    />
                )}
                <Image
                  className="clickable"
                  src={row?.image?.[0]}
                  fluid
                  style={{
                    maxHeight: '100%',
                    maxWidth: '100%'
                  }}
                  onClick={() => this.props.showImages(row.image)}
                />
              </div>

              <div className="clickable" onClick={() => this.props.editProduct(row)}>
                {row.vendor && <span className="mr-2">{row.vendor}</span>}
                {!row.vendor && 
                  <span className="text-muted mr-2">
                    <FaExclamationTriangle className="mr-1" />Vendor
                  </span>}
                {row.model && <span>{row.model}</span>}
                {!row.model && 
                  <span className="text-muted mr-2">
                    <FaExclamationTriangle className="mr-1" />Model
                  </span>}
              </div>
              <div>
                {row.partNumber && (
                  <small className="text-muted mr-2">
                    <FaHashtag/>{row.partNumber}
                  </small>
                )}
                {row.upc && 
                  <small className="text-muted mr-2">
                    <FaBarcode className="mr-1"/>{row.upc}
                  </small>
                }
                {!row.upc && 
                  <small className="text-muted mr-2">
                    <FaExclamationTriangle className="mr-1"/>UPC
                  </small>
                }
              </div>
              <div>
                <small>
                  {!!(orderedQty > 0) && (
                    <span className="text-primary mr-2"><FaCartPlus /> {orderedQty}x</span>
                  )}
                  <StockWarehouse
                    listings={listings}
                    product={row}
                    stock={row.stock}
                    editListing={editListing}
                    editStock={() => this.props.editStock(row)}
                    compact={true}
                  />
                </small>
              </div>
            </div>
          </Col>
          <Col xs={12} md={3} className="product-details mb-2 mb-md-0">
            <div className="title">
              {row.created && (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id={`tooltip-top`}>Creation Date</Tooltip>}
                >
                  <small className="text-muted mr-2">
                    <WiSunrise className="text-muted" />
                    {calculateAge(row.created)}
                  </small>
                </OverlayTrigger>
              )}
              {row.stockOutDate && (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id={`tooltip-top`}>Stock Out</Tooltip>}
                >
                <small className="text-muted mr-2">
                    <FaRegCalendarTimes className="mr-1 mb-1" />
                    {calculateAge(row.stockOutDate)}
                  </small>
                </OverlayTrigger>
              )}
              <small className="text-muted">{row.title}</small>
            </div>
          </Col>
          <Col xs={12} md={2}>
            <ProductListingsWidget
              listings={listings}
              product={row}
              editListing={editListing}
            />
          </Col>
          <Col xs={12} md={2}>
            <div>
              {row.lastSaleDate && (
                <small className="text-muted mr-2">
                  <FaDollarSign />
                  {calculateAge(row.lastSaleDate)}
              </small>
              )}
            </div>
            <div>
              <SalesAnalytics product={row} />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

const Duplicate = props => {
  const { duplicates, row } = props;
  
  const overlay = (
    <Popover 
      title={`Possible Duplicates for ${row.model}`}
      id="manifest-item-popover">
        <ul>
          {duplicates.map(product =>
            <li key={product.key}>
              {product.vendor} 
              {product.model} 
              ({product.stitch && product.stitch.vid})
            </li>
          )}
        </ul>
    </Popover>        
  );
  
  const filteredDuplicates = duplicates.map(product => {
    if (product.key !== row.key 
      && product.model.toUpperCase() === row.model.toUpperCase() 
      && !product.archived) {
        return product;
    } else {
      return null;
    }  
  }).filter(Boolean);
  
  if (!filteredDuplicates.length) {
    return ( <span></span> );
  } 
  
  return (
    <OverlayTrigger 
      rootClose
      trigger="click" 
      placement="bottom"
      overlay={overlay}>
        <FaCopy className="duplicate text-warning" />
    </OverlayTrigger>
  );
}

const Pricing = (props) => {
  const excludeChannels = [ 'Amazon MX', 'Amazon CA' ];
  const { listings } = props;
  const pricing = [];

  Object.keys(listings || {}).map(listingId => {
    const listing = listings[listingId];
    const price = parseInt(listing.price || listing.channelPrice, 10);

    if (price && !excludeChannels.includes(listing.channel)) {
      pricing.push(price);
    }
    
    return null;
  });
  
  let uniquePricing = [ ...new Set(pricing) ];
  const maxPrice = '$' + Math.max(...uniquePricing).toFixed(2);
  const minPrice = '$' + Math.min(...uniquePricing).toFixed(2);
  
  if (!uniquePricing.length) {
    return 'None';
  } else if (uniquePricing.length === 1) {
    return maxPrice;
  } else {
    return `${minPrice} - ${maxPrice}`;
  }
}

const SalesAnalytics = (props) => {
  const { product } = props;
  
  if (product.analytics && product.analytics.sale) {
    const sold = product.analytics.sale;
    const period = (sold[365] && '365') || (sold.all && 'all');
    
    if (period) {
      const revenue = formatAmount(sold[period].sales);
      const units = sold[period].sold;
      const price = formatAmount(sold[period].sales / units)
      const old = period === '365' ? '' : 'text-muted';
    
      return (
        <Container fluid>
          <Row>
            <Col>
              <span className={old}>
                <FaMoneyBillWave className={`sales-icon sales-${period}`} />
                ${revenue}
              </span>
            </Col>
          </Row>
          <Row>
            <Col>
              <small className="text-muted">
                {units} sold | ${price} ea.
              </small>
            </Col>
          </Row>
        </Container>  
      );
    }      
  }
  
  return null;
}

export { ProductRow };