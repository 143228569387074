import React, { useEffect } from 'react';

function DragDropHandler({ onAddImage, upload }) {
  // Helper function to handle pasted or dropped items
  const handlePasteOrDrop = async (items) => {
    console.log('handlePasteOrDrop', items);
    for (const item of items) {
      if (item.kind === 'file' && item.type.startsWith('image/')) {
        // Handle image file
        const file = item.getAsFile();
        if (file) {
          // Upload the file and get the local URL
          upload(file, onAddImage);
        }
      } else if (item.kind === 'string' && item.type === 'text/plain') {
        // Handle potential image URL
        try {
          const text = await new Promise((resolve) => item.getAsString(resolve));
          if (text.match(/^https?:\/\/.+\.(jpg|jpeg|png|gif|webp)$/i)) {
            onAddImage(text); // External URLs still handled directly
          }
        } catch (error) {
          console.error('Error processing pasted content:', error);
        }
      }
    }
  };

  useEffect(() => {
    const handlePaste = (e) => {
      console.log('handlePaste', e);
      if (e.clipboardData.items) {
        handlePasteOrDrop(Array.from(e.clipboardData.items));
      }
    };

    const handleDrop = async (e) => {
      e.preventDefault();
      console.log('handleDrop', e);
      if (e.dataTransfer.files.length > 0) {
        // Upload files and get local URLs
        for (const file of Array.from(e.dataTransfer.files)) {
          if (file.type.startsWith('image/')) {
            const localUrl = await upload(file);
            onAddImage(localUrl);
          }
        }
      } else if (e.dataTransfer.items) {
        // Fall back to items if no files
        handlePasteOrDrop(Array.from(e.dataTransfer.items));
      }
    };

    const handleDragOver = (e) => {
      e.preventDefault();
    };

    document.addEventListener('paste', handlePaste);
    document.addEventListener('drop', handleDrop);
    document.addEventListener('dragover', handleDragOver);

    return () => {
      document.removeEventListener('paste', handlePaste);
      document.removeEventListener('drop', handleDrop);
      document.removeEventListener('dragover', handleDragOver);
    };
  }, [onAddImage, upload]); // Re-run effect if onAddImage or upload changes

  return null; // This component does not render anything
}

export { DragDropHandler };
