import React, { Component } from 'react';
import { IconContext } from 'react-icons';
import { HashRouter as Router } from 'react-router-dom';
import { NavBarTop } from './NavBarTop';
import { Login } from './Login';
import { ContentContainer } from './ContentContainer';
import { BatchProvider } from './BatchProvider';
import { NotificationProvider } from './NotificationProvider';
import firebase from './fire';
import elasticsearch from 'elasticsearch';


// CSS imports
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-toastify/dist/ReactToastify.css';

// TC3EEaAc1zvNL5Dyj4pV

const esc = new elasticsearch.Client({
  host: {
    protocol: 'https',
    host: 'elasticsearch.newfanglednetworks.com',
    port: 443,
    headers: {
      'Authorization': 'Basic ZXN1c2VyOlRDM0VFYUFjMXp2Tkw1RHlqNHBW'
    }
  }
  //,log: 'trace'
});

class App extends Component {
  constructor(props) {
    super(props);

    this.state = { 
      user: null,
      messages: [],
      results: [],
      query: ''
    };
    
    this.location = false;
    
    this.search = {
      batchQuery: this.batchQuery.bind(this),
      runQuery: this.runQuery.bind(this),
      setFilter: this.setFilter.bind(this),
      setIndex: this.setIndex.bind(this),
      setPage: this.setPage.bind(this),
      setQuery: this.setQuery.bind(this),
      setSort: this.setSort.bind(this),
      simpleQuery: this.simpleQuery.bind(this),
      transformQuery: this.transformQuery.bind(this)
    };
 
    //this.handleLogin = this.handleLogin.bind(this);
    
    this.setFilter = this.setFilter.bind(this);
    this.setIndex = this.setIndex.bind(this);
    this.setPage  = this.setPage.bind(this);
    this.runQuery = this.runQuery.bind(this);
    this.setQuery = this.setQuery.bind(this);
    this.setSort  = this.setSort.bind(this);
    
    //this.runQuery();
    this.initSearch();
  }
  
  componentDidMount() {
    firebase.auth().onAuthStateChanged(
      user => {
        if (!user) {
          this.setState({ user });
        } else {
          firebase.database()
            .ref(`/user/${user.uid}`).on('value', snap => 
              this.setState({ user: { ...snap.val(), uid: snap.key } })
            );
        }
      });
  }
  
  escapeQuery(query) {
    return '(' + query.replaceAll(/([/+-])/g, "\\$1") + ')';
  }
  
  getQuery() {
    const s = this.search;
    const query = (s.transform ? s.transform(s.query) : s.query);
    
    if (s.filter && s.filter !== '') {
      console.log('filter', s.filter);
      return s.filter + ' AND ' + this.escapeQuery(query);
    } else {
      return this.escapeQuery(query);
    }
  }
  
  initSearch() {
    const s = this.search;
  
    s.count = 0;
    s.filter = '';
    s.index = '';
    s.page = 1;
    s.pages = 0;
    s.perPage = 10;
    s.query = s.query || '*';
    s.sortStr = '';
    s.transform = null;
  }
  
  simpleQuery(index, query) {
    const q = this.escapeQuery(query);
  
    return new Promise((res, rej) => {
      esc
        .search({ index, q, analyzeWildcard: true })
        .then(response => {
          res(response.hits.hits.map(hit => 
            ({ ...hit._source, key: hit._id })
          ));
        });
    });  
  }
  
  batchQuery() {
    return new Promise((res, rej) => {
      esc.search({
        index: this.search.index,
        type: this.search.index,
        q: this.getQuery(),
        size: 10000,
        storedFields: [],
        analyzeWildcard: true,
        defaultOperator: 'AND',
      }).then(response => {
        res(response.hits.hits.map(hit => ({ key: hit._id })));
      }, err => {
        console.trace(err.message);
        rej(err.message);
      });
    });   
  }
  
  
  
  runQuery(newQuery = false) {
    let queryLocation = window.location.href;
    
    const sortFields = this.search.sortFields
      .filter(field => typeof field !== 'object');

    const sourceFields = this.search.sortFields
      .filter(field => typeof field === 'object')
    
    // @TODO  Probably don't need a variable for this. Compare query and filter.
    if (newQuery) this.search.page = 1;

    esc.search({
      index: this.search.index,
      type: this.search.index,
      q: this.getQuery(),
      size: this.search.perPage,
      from: (this.search.page - 1) * this.search.perPage,
      // Only add the 'sort' parameter if sortFields is not empty
      ...(sortFields?.length ? { sort: sortFields } : {}),
      ...(sourceFields?.length ? { source: JSON.stringify({ sort: sourceFields }) } : {}),
      analyzeWildcard: true,
      defaultOperator: 'AND',
    }).then(response => {
      let results = [];

      this.search.location = queryLocation;
      this.search.count = response.hits.total;
      this.search.pages = 
        Math.ceil(this.search.count / this.search.perPage, 10);
      
      response.hits.hits.map(hit => {
        hit._source.key = hit._id;
        return results.push(hit._source);
      });
      
      this.setState({ results: results });
    }, err => {
      console.trace(err.message);
    });
  }
  
  setFilter(filter) {
    this.search.filter = filter;    
  }
  
  setIndex(index, sortStr = '', filter) {
    this.setState({ results: [] });
    this.initSearch();
    this.setSort(sortStr);

    this.search.index = index;
    this.search.filter = filter;

    this.runQuery();
  }
  
  setPage(pageNum) {
    let maxPages = this.search.count / this.search.perPage + 1;

    if (pageNum > 0 && pageNum <= maxPages) {
      this.search.page = pageNum;
    }
    
    this.runQuery();  
  }
  
  setQuery(queryString) {
    this.search.query = queryString;
  }
  
  setSort(fields) {
    if (Array.isArray(fields)) {
      this.search.sortFields = fields.map(field => {
        const fieldParts = field.replace(/\s+/g, '').split(':');
        const scriptParts = fieldParts[0].split('|');

        // Handle scripted fields sorting (aggregation etc)
        if (scriptParts.length === 2) {
          return {
            _script: {
              type: 'number',
              script: {
                lang: 'painless',
                inline: scriptParts[1]
              },
              order: fieldParts[1]
            }
          };
        }

        return fieldParts.join(':');
      });
    } else if (typeof fields === 'string' && fields.length) {
      this.search.sortFields = [`${fields}:desc`];
    }
  }
  
  transformQuery(cb) {
    this.search.transform = cb;
  }
  
  render() {
    const user = this.state.user;
    const path = window.location.pathname;
    const pathArr = path.split('/').filter(x => x);
    
    if (!user || !user.uid) {
      return (              
        <Login auth={firebase.auth} />
      );
    } else {
      return (
        <IconContext.Provider value={{ className: "icon" }}>
          <NotificationProvider>
            <BatchProvider>
          
              <div>
                <Router>
                  <NavBarTop 
                    auth={firebase.auth}
                    user={user}
                    runQuery={this.runQuery} 
                    setQuery={this.setQuery} />
                  <ContentContainer
                    user={user} 
                    results={this.state.results} 
                    search={this.search} />
                </Router>
              </div>
          
            </BatchProvider>
          </NotificationProvider>
        </IconContext.Provider>
    );
    }
  }
}

export default App;
