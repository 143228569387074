import React, { useState } from 'react';
import { Nav, Navbar, Form, FormControl, Button } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';

const NavBarTop = ({ user, auth, setQuery, runQuery }) => {
  const [query, setLocalQuery] = useState('');
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.preventDefault();

    if (e.target.name === 'reset') {
      setLocalQuery('');
      setQuery('*');
    }

    runQuery(true);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') runQuery(true);
  };

  const logout = () => {
    auth().signOut();
    navigate('/');
  };

  const handleUpdate = (e) => {
    setLocalQuery(e.target.value);
    setQuery(e.target.value);
  };

  return (
    <Navbar bg="dark" variant="dark" fixed="top" expand="lg">
      <Navbar.Brand href="#products">AllThing</Navbar.Brand>
      <Navbar.Toggle />
      <Form className="d-flex flex-grow-1 justify-content-end mx-2">
        <FormControl 
          type="text" 
          placeholder="Search" 
          value={query}
          onChange={handleUpdate} 
          onKeyPress={handleKeyPress} 
          className="mr-sm-2" 
        />
        <Button 
          name="search" 
          variant="outline-info" 
          onClick={handleClick} 
          className="d-none d-lg-block"
        >
          Search
        </Button>
        &nbsp;
        <Button 
          name="reset" 
          variant="outline-info" 
          onClick={handleClick} 
          className="d-none d-lg-block"
        >
          Reset
        </Button>
      </Form>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          {user && (
            <>
              <Nav.Item className="px-2">
                <NavLink
                  to="/products"
                  className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
                >
                  Products
                </NavLink>
              </Nav.Item>
              <Nav.Item className="px-2">
                <NavLink
                  to="/listings/all"
                  className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
                >
                  Listings
                </NavLink>
              </Nav.Item>

              <Nav.Item className="px-2">
                <NavLink
                  to="/purchases"
                  className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
                >
                  Purchases
                </NavLink>
              </Nav.Item>
              <Nav.Item className="px-2">
                <NavLink
                  to="/sales"
                  className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
                >
                  Sales
                </NavLink>
              </Nav.Item>

              {/* ... other Nav.Items ... */}
            </>
          )}
        </Nav>
        <Nav className="px-4">
          <Nav.Link onClick={logout}>Logout {user.name}</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export { NavBarTop };
