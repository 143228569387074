import React from 'react';
import { Row, Col, ListGroup, Card } from 'react-bootstrap';
import { FaComment, FaHashtag, FaQuestionCircle, FaExchangeAlt, FaCalendarAlt, FaTruck } from 'react-icons/fa';

const ReturnEdit = ({ row }) => {
  console.log('row', row);

  return (
    Object.entries(row.return || {}).map(([rmaId, returnData]) => (
      <Card className="mb-3" key={rmaId}>
        <Card.Header>
          <strong>RMA # {rmaId} - </strong>
          <span className="badge badge-warning">
            {returnData.classification}
          </span>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col xs={12} md={6}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <FaComment className="mr-2" />
                  <strong>Comment:</strong> {returnData.comment}
                </ListGroup.Item>
                <ListGroup.Item>
                  <FaHashtag className="mr-2" />
                  <strong>Return Quantity:</strong> {returnData.quantity}
                </ListGroup.Item>
                <ListGroup.Item>
                  <FaQuestionCircle className="mr-2" />
                  <strong>Reason:</strong> {returnData.reasonText}
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col xs={12} md={6}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <FaExchangeAlt className="mr-2" />
                  <strong>Replace:</strong> {returnData.replace ? 'Yes' : 'No'}
                </ListGroup.Item>
                <ListGroup.Item>
                  <FaCalendarAlt className="mr-2" />
                  <strong>Requested:</strong> {
                    returnData.requested ? 
                    new Date(returnData.requested).toLocaleDateString('en-US', 
                      { year: 'numeric', month: 'long', day: 'numeric' }
                    ) : 'N/A'
                  }
                </ListGroup.Item>
                <ListGroup.Item>
                  <FaTruck className="mr-2" />
                  <strong>Tracking:</strong> {returnData.tracking}
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    ))
  );
};

export { ReturnEdit };
