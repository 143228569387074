import React, { useState, useEffect, useRef } from 'react';
import { Badge, Image } from 'react-bootstrap';
import { 
  FaCheckCircle, 
  FaChevronCircleLeft, 
  FaChevronCircleRight, 
  FaImage, 
  FaPlus, 
  FaRegTrashAlt, 
  FaSearchMinus 
} from 'react-icons/fa';
import { Upload, saveFile } from './Upload';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { DragDropHandler } from './DragDropHandler';

function Slideshow(props) {
  const { selected: selectedProp = [], images: imagesProp, maxHeight: maxHeightProp = '500', showThumbnails: showThumbnailsProp = true } = props;

  // State hooks
  const [index, setIndex] = useState(0);
  const [selected, setSelected] = useState(selectedProp);
  const [images, setImages] = useState(() => {
    if (Array.isArray(imagesProp)) {
      return Array.from(new Set([...imagesProp, ...selectedProp]));
    } else if (imagesProp) {
      return Array.from(new Set([imagesProp, ...selectedProp]));
    }
    return [];
  });
  const [isZoomed, setIsZoomed] = useState(true);
  const [showThumbnails, setShowThumbnails] = useState(showThumbnailsProp);

  // Ref hook
  const transformRef = useRef(null);

  // Effect hook for initial select callback
  useEffect(() => {
    if (typeof props.select === 'function') {
      selectedProp.forEach(url => {
        if (images.includes(url)) {
          props.select(url);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Effect hook to handle updates to props.images
  useEffect(() => {
    if (imagesProp !== props.images) {
      if (Array.isArray(imagesProp)) {
        setImages(Array.from(new Set(imagesProp)));
      } else if (imagesProp) {
        setImages([imagesProp]);
      } else {
        setImages([]);
      }
    }
  }, [imagesProp, props.images]);

  // Handler functions
  const addImage = (url) => {
    console.log(url);
    if (typeof props.add === 'function') {
      props.add(url);
    }
    setImages(prevImages => [...prevImages, url]);
  };

  const removeImage = (removeIndex) => {
    if (typeof props.remove === 'function') {
      props.remove(removeIndex);
    }
    setImages(prevImages => prevImages.filter((_, idx) => idx !== removeIndex));
    // Adjust index if necessary
    setIndex(prevIndex => (removeIndex <= prevIndex && prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };

  const selectImage = (selectIndex) => {
    const selectedImage = images[selectIndex];
    if (typeof props.select === 'function') {
      props.select(selectedImage);
    }

    setSelected(prevSelected => {
      if (prevSelected.includes(selectedImage)) {
        return prevSelected.filter(s => s !== selectedImage);
      } else {
        return [...prevSelected, selectedImage];
      }
    });
  };

  const setImage = (setIdx) => {
    setIndex(setIdx);
    if (transformRef.current) {
      transformRef.current.resetTransform();
    }
  };

  const advanceImage = (delta) => {
    setIndex(prevIndex => {
      const newIndex = prevIndex + delta;
      if (newIndex >= images.length) return 0;
      if (newIndex < 0) return images.length - 1;
      return newIndex;
    });
  };

  const resetZoom = () => {
    if (transformRef.current) {
      transformRef.current.resetTransform();
    }
  };

  // Derived variables
  const currentImage = images[index];
  const classSelected = selected.includes(currentImage) ? 'selected' : '';

  return (
    <div>
      <DragDropHandler onAddImage={addImage} upload={saveFile} />

      <div className={`image-carousel container ${classSelected}`}>
        <div className={'embed' in props ? 'image' : ''}>
          {images.length > 0 && currentImage.length > 0 ? (
            'embed' in props ? (
              <Image
                src={currentImage}
                style={{
                  maxWidth: '100%',
                  maxHeight: `${maxHeightProp}px`,
                  objectFit: 'contain',
                  width: 'auto',
                  height: 'auto',
                }}
                onClick={() => props.show && props.show(images)}
              />
            ) : (
              <TransformWrapper
                fitOnInit={true}
                ref={transformRef}
                defaultPositionX={0}
                defaultPositionY={0}
                options={{
                  limitToBounds: true,
                  minScale: 0.1,
                  maxScale: 3,
                }}
              >
                {({ resetTransform, scale }) => (
                  <>
                    <TransformComponent
                      wrapperStyle={{
                        width: '100%',
                        height: '100%',
                        maxHeight: `${maxHeightProp}px`,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      contentStyle={{
                        width: '100%',
                        height: '100%',
                        maxHeight: `${maxHeightProp}px`,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        src={currentImage}
                        style={{
                          maxWidth: '100%',
                          maxHeight: `${maxHeightProp}px`,
                          objectFit: 'contain',
                          width: 'auto',
                          height: 'auto',
                        }}
                        onClick={() => props.show && props.show(images)}
                      />
                    </TransformComponent>
                    {scale > 1 && (
                      <span className="zoom" onClick={resetZoom}>
                        <FaSearchMinus />
                      </span>
                    )}
                  </>
                )}
              </TransformWrapper>
            )
          ) : (
            <FaImage className="placeholder" />
          )}
        </div>

        {props.add && (
          <span className="add">
            <Upload onUpload={addImage}>
              <FaPlus />
            </Upload>
          </span>
        )}

        {props.remove && (
          <span className="remove">
            <FaRegTrashAlt onClick={() => removeImage(index)} />
          </span>
        )}

        {props.select && (
          <span className={`select ${classSelected}`}>
            <FaCheckCircle
              className={classSelected}
              onClick={() => selectImage(index)}
            />
          </span>
        )}

        {'next' in props && images.length > 1 && (
          <span className="next">
            <FaChevronCircleRight onClick={() => advanceImage(1)} />
          </span>
        )}

        {'prev' in props && images.length > 1 && (
          <span className="prev">
            <FaChevronCircleLeft onClick={() => advanceImage(-1)} />
          </span>
        )}
      </div>

      {showThumbnails && images.map((url, idx) => {
        if (!url?.length) return null;

        const thumbSelected = selected.includes(url) ? 'selected' : '';

        return (
          <div className="thumb-container" key={idx}>
            <div className="thumb-wrapper">
              <Image
                src={url}
                className={`thumb ${thumbSelected}`}
                onClick={() => setImage(idx)}
              />

              {props.select && (
                <span className={`select ${thumbSelected}`}>
                  <Badge
                    variant="primary"
                    onClick={() => setImage(idx)}
                  >
                    {selected.indexOf(url) + 1}
                  </Badge>
                </span>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export { Slideshow };