import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Form, Image, Container, Row, Col, Badge } from 'react-bootstrap';
import { FaPencilAlt, FaFileInvoiceDollar, FaLongArrowAltRight, FaMinusCircle, FaExclamationTriangle, FaBoxes, FaRegCalendarAlt, FaMagic } from 'react-icons/fa';
import { getChannelIcon, getListing, getListingLinks, getProduct, formatDate } from './AllThing';
import { StockWarehouse } from './StockWarehouse';
import { showImages } from './functionalUtils';
import { updatePath } from './fire';

const ListingRow = ({ row: initialRow, isChecked, handleCheck, editListing, editStock, linkListing, showModal }) => {
  const [row, setRow] = useState(initialRow);
  const [product, setProduct] = useState({});
  const [listings, setListings] = useState({});

  useEffect(() => {
    if (row && row.image) {
      if (!Array.isArray(row.image)) {
        setRow(prevRow => ({ ...prevRow, image: [prevRow.image] }));
      }
    }
  }, []);

  useEffect(() => {
    getListing(row.key, snap => {
      setRow({ ...snap.val(), key: snap.key });
    });
  }, [row.key]);

  useEffect(() => {
    if (row.product) {
      getProduct(row.product, snap => {
        const productData = { ...snap.val(), key: snap.key };
        setProduct(productData);
      });
    }
  }, [row.product]);

  useEffect(() => {
    Object.keys(product.listing || {}).forEach(id => 
      getListing(id, snap => {
        setListings(prevListings => ({
          ...prevListings,
          [snap.key]: snap.val()
        }));
      })
    );
  }, [product]);

  const identifyListing = useCallback(async (asin) => {
    const endpoint = 'https://dev.newfanglednetworks.com:8080/identifyListing';  
    const response = await axios.get(`${endpoint}?asin=${asin}`);
    
    if (response?.data?.listingUpdate) {
      console.log('/listing', row.key, response.data.listingUpdate);
      updatePath(`/listing/${row.key}`, response.data.listingUpdate);
    }
    
    if (response?.data?.results?.length && linkListing) {
      const productKey = response.data.results[0].key;
      const updatedListing = { ...row, ...response.data.listingUpdate, product: productKey };
      const productSnap = await getProduct(productKey);
      
      linkListing(updatedListing, productSnap.val(), listings);
    }
  }, [row, listings, linkListing]);

  const renderListings = () => {
    const links = getListingLinks(row, product).urls;
    const images = [];

    if (row.image) {
      if (typeof row.image === 'object' && row.image.length >= 0) {
        images.push(...row.image);
      } else if (typeof row.image === 'object') {
        images.push(...Object.values(row.image));
      } else {
        images.push(row.image);
      }
    } else {
      images.push('/img/placeholder.svg');
    }
    
    return (
      <div className="listing-row">
        <Container fluid>
          <Row>
            <Col sm={ 5 } className="listing-detail">
              <Form.Check
                className="float-left"
                checked={ isChecked(row.key) } 
                type="checkbox" 
                onChange={() => handleCheck(row.key) } />
              { images.length &&
                <div className="image-carousel float-left">
                  <div className="image">
                    <Image 
                      fluid 
                      src={ images[0] } 
                      className="clickable"
                      onClick={() => showImages(showModal, images)} />              
                  </div>
                </div>
              }
              <div className="channel">
                <span>
                  <a href={ links[1] || links[0] } target="_blank" 
                    rel="noopener noreferrer">
                      { getChannelIcon(row.channel) }
                  </a>
                </span>
                <strong>{ row.channel }</strong>
                { row.active === true && row.suppressed === true && 
                  <FaExclamationTriangle className="text-warning" />
                }
                { 'active' in row && row.active === false &&
                  <FaMinusCircle className="text-danger" />
                }

                <FaMagic 
                  className="clickable"
                  onClick={ () => identifyListing(row.parentId) } />                
              </div>
              <div className="title text-truncate">
                <small>
                  { row.model && 
                    <strong>{ row.model } </strong>
                  }
                  <a href={ links[0] } target="_blank" 
                    rel="noopener noreferrer" className="text-muted">
                      { row.title }
                  </a>
                </small>
              </div>
              <div className="channel availability">
                <span><FaBoxes />{ row.channelStock }</span>
                <span><FaFileInvoiceDollar />{ row.channelPrice }</span>
                <span>
                  <FaRegCalendarAlt />          
                  {formatDate(row.created, 'M/D/YY')}
                </span>
              </div>
            </Col>
            <Col sm={ 1 }>
              <span className="condition">
                { row.channelCondition && row.channelCondition.toUpperCase() }
              </span>
              <FaLongArrowAltRight 
                className="clickable" 
                onClick={ () => linkListing(row, product, listings) } />
              <span className="condition">
                { row.condition && row.condition.toUpperCase() }
              </span>
            </Col>
            <Col sm={ 5 } className="listing-detail">
              { product.image &&
                <div className="image-carousel float-left">
                  <div className="image">
                    <Image 
                      fluid 
                      src={ product.image[0] } 
                      className="clickable"
                      onClick={() => showImages(showModal, product.image)} />              
                  </div>
                </div>
              }
              <div className="channel">
                <Badge variant="secondary" bg="secondary">
                  { row.condition && row.condition.toUpperCase() }
                </Badge>
                <strong>{ product.vendor } { product.model }</strong>
              </div>
              <div className="title text-truncate">
                <small className="text-muted">
                  { product.title }
                </small>
              </div>
              <div className="availability">
                { product && product.model &&
                <StockWarehouse 
                  listings={ listings }
                  product={ product }
                  stock={ product.stock }
                  editListing={ editListing }
                  editStock={ () => editStock(product) } />     
                }
              </div>
            </Col>
            <Col sm={ 1 } className="text-right">
              <FaPencilAlt onClick={e => editListing(row)} />
            </Col>
          </Row>
        </Container>
      </div>
    );
  };

  return renderListings();
};

export { ListingRow };
