import React from 'react';
import { Row, Col, Badge } from 'react-bootstrap';
import { FaPencilAlt, FaMinusCircle, FaExclamationTriangle, FaUnlink } from 'react-icons/fa';
import { AllThingComponent, getChannelIcon, getListing, getListingLinks, unlinkListing, formatDate } from './AllThing';


class Listing extends AllThingComponent {
  constructor(props) {
    super(props);
    
    this.state.row = this.props.row;
    this.state.product = this.props.product;
    
    // Retrieve complete listing details from datastore
    getListing(this.props.row.key).then(    
      snap => this.setState({ row: { 
        key: snap.key, ...snap.val() 
      }})
    );         
  }
  
  render() {
    const editListing = this.props.editListing;
    const row = this.state.row;
    const product = this.state.product;
    const images = [];
    
    const condition = row.channelCondition || row.condition || 'NEW';
    const links = getListingLinks(row, product).urls;
    const price = parseFloat(row.channelPrice || row.price);
    
    console.log('PRODUCT');console.log(product);

    if (row.image) {
      if (typeof row.image === 'object' && row.image.length >= 0) {
        images.push(...row.image);
      } else if (typeof row.image === 'object') {
        images.push(...Object.values(row.image));
      } else {
        images.push(row.image);
      }
    } else {
      images.push('/img/placeholder.svg');
    }    
  
    return (
    <>
      { this.renderModal() }
      <Row className="listing-row">
        <Col sm={ 8 } className="nowrap nocrowd nooverflow">
          <span>
            <a href={ links[1] || links[0] } target="_blank" 
              rel="noopener noreferrer">
                { getChannelIcon(row.channel) }
            </a>
          </span>
          <small>
            <Badge variant="secondary" bg="secondary">
              { condition.toUpperCase() } 
            </Badge>
            { (row.childId || row.parentId) &&
              <span>
                <strong>                                
                  { row.childId || row.parentId }
                </strong>
              </span>
            }
            <span>
              <a href={ links[0] } target="_blank" 
                rel="noopener noreferrer" className="text-muted">
                  { row.title }
              </a>
            </span>
          </small>            
        </Col>
        <Col className="nowrap nocrowd">        
          <span>{ row.channelStock || row.stock || 0 }x</span>
          <span className="price text-success">
            ${ (price > 0 && price.toFixed(2)) || 'NA' }
          </span>
          <span>
            { row.active === true && row.suppressed === true && 
              <FaExclamationTriangle className="text-warning" />
            }
            { 'active' in row && row.active === false &&
              <FaMinusCircle className="text-danger" />
            }
          </span>
          {/* Add formatted date here */}
          <span className="text-muted">
            { formatDate(row.createdAt) }
          </span>
        </Col>
        <Col className="nowrap nocrowd">
          <span>
            <FaPencilAlt 
              className="clickable" 
              onClick={ e => editListing(row, product) } />
          </span>
          <span>
            <FaUnlink
              className="clickable"
              onClick={ e => unlinkListing(row.key) } />
          </span>
        </Col>
      </Row>
    </>
    );
  }
}

export { Listing };
