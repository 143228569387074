export const productSort = {
  label: 'Sort',
  multiple: false,
  options: [
    { value: 'analytics.sale.365.sales', label: 'Annual Sales' },
    { value: 'lastSaleDate', label: 'Last Sale' },
    { value: 'stockOutDate', label: 'Stock Out' },
    { value: 'created', label: 'Created' },
    { value: 'listing.pricing.buyboxOffer.updated', label: 'Buybox Update' },
    { value: `_script|
      doc['stock.fba.available'].value +
      doc['stock.pri.available'].value +
      doc['stock.new.available'].value +
      doc['stock.likenew.available'].value +
      doc['stock.src.available'].value +
      doc['stock.ref.available'].value +
      doc['stock.use.available'].value +
      doc['stock.res.available'].value
      `, label: 'Stock (all)' }
  ]
}

export const productStatusFilter = {
  label: 'Status',
  multiple: false,
  options: [
    { value: 'NOT archived:true AND NOT title:DUPLICATE', label: 'Active' },
    { value: 'NOT _exists_:item.product', label: 'Restock' },
    { value: 'archived:true', label: 'Archived' },
    { value: 'title:DUPLICATE', label: 'Duplicate' }
  ]
};

export const productChannelFilter = {
  label: 'Store',
  multiple: true,
  operator: 'OR',
  options: [
    { value: 'listing.channel:"Amazon US"', label: 'Amazon US' },
    { value: 'listing.channel:"Amazon CA"', label: 'Amazon CA' },
    { value: 'listing.channel:"eBay"', label: 'eBay' },
    { value: 'listing.channel:"BrokerBin"', label: 'BrokerBin' },
    { value: 'listing.channel:"Shopify"', label: 'Shopify' },
    { value: 'listing.channel:"NewEgg"', label: 'NewEgg' },
    { value: 'listing.channel:"Walmart"', label: 'Walmart' },
  ]
};

export const productPricingFilter = {
  label: 'Pricing',
  multiple: true,
  operator: 'OR',
  options: [
    { value: 'listing.pricing.myOffer.buybox:true', label: 'Buybox' },
    { value: 'listing.pricing.myOffer.featured:true', label: 'Featured' },
  ]
};

export const productStockFilter = {
  label: 'Stock',
  multiple: true,
  operator: 'OR',
  options: [
    { value: '(stock.pri.available:>0 OR stock.new.available:>0 OR stock.likenew.available:>0 OR stock.fba.available:>0 OR stock.src.available:>0)', label: 'New (Any)' },
    { value: 'stock.fba.available:>0', label: 'FBA' },
    { value: 'stock.pri.available:>0', label: 'Prime' },
    { value: 'stock.new.available:>0', label: 'New' },
    { value: 'stock.likenew.available:>0', label: 'Like New' },
    { value: 'stock.ref.available:>0', label: 'Refurbished' },
    { value: 'stock.src.available:>0', label: 'Sourced' },
    { value: 'stock.use.available:>0', label: 'Used' },
    { value: 'stock.res.available:>0', label: 'Reserved' },
    { value: 'stock.res.available:<0', label: 'Oversold' }
  ]
};


export const saleSort = {
  label: 'Sort By',
  multiple: false,
  options: [
    { value: 'ordered', label: 'Ordered' },
  ]
};

export const saleChannelFilter = {
  label: 'Stores',
  multiple: true,
  operator: 'OR',
  options: [
    { value: 'channel:"Amazon US"', label: 'Amazon US' },
    { value: 'channel:"Amazon CA"', label: 'Amazon CA' },
    { value: 'channel:"eBay"', label: 'eBay' },
    { value: 'channel:"BrokerBin"', label: 'BrokerBin' },
    { value: 'channel:"Shopify"', label: 'Shopify' },
    { value: 'channel:"NewEgg"', label: 'NewEgg' },
    { value: 'channel:"Walmart"', label: 'Walmart' },
  ]
};

export const saleStatusFilter = {
  label: 'Status',
  multiple: false,
  operator: 'OR',
  options: [
    {
      value: `
        (NOT status:"Cancelled" OR NOT status:"Canceled") AND 
        ((NOT _exists_:tracking) OR (shipping.status:"") OR (shipping.status:"InfoReceived")) AND
        ordered:>=${new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0]}
      `,
      label: 'Open'
    },
    { 
      value: '_exists_:return.tracking', 
      label: 'Return' 
    },
    { 
      value: 'NOT _exists_:item.product AND NOT channel:"ShipStation" AND NOT channel:"20140"', 
      label: 'Unlinked' 
    },
    { 
      value: `
        shipping.cutoff:<=${new Date().toISOString().split('T')[0]} AND 
        NOT _exists_:tracking AND 
        NOT status:Cancelled
      `, 
      label: 'MUST SHIP TODAY' 
    }
]
};

// Optional: Keep the original filterDefinitions object for backwards compatibility
export const filterDefinitions = {
  archived: productStatusFilter,
  channels: productChannelFilter,
  fgStock: productStockFilter
};
