import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import { QuickSearch } from './QuickSearch';

class QuickEdit extends Component {
  constructor(props) {
    super(props);    
        
    this.state = {
      edit: false,
      options: props.options
    };
    
    this.lastEvent = { target: { name: this.props.name, value: '' } };

    this.searchRef = React.createRef();
    this.editRef = React.createRef();

    // Function scope bindings
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.startEdit = this.startEdit.bind(this);
    this.endEdit = this.endEdit.bind(this);
  }
  
  // Autofocus form control when in edit mode
  componentDidUpdate() {
    const options = this.state.options;
    
    if (options && options.length && options.length > 10) {
      this.searchRef.current.focus();
    } else {
      this.editRef.current.focus();
    }
  }
  
  // Because we want to consider two elements together as a focus / blur "unit",
  // we need to use setTimeout to consider whether focus was changed from one
  // to the other.  Changing focus inherently means one element is unfocused
  // *before* another is focused (though near instantaneously).
  handleBlur() {
    setTimeout(() => {
      if (document.activeElement !== this.searchRef.current
        && document.activeElement !== this.editRef.current) this.endEdit();
    }, 10);
  }
  
  handleChange(event) {
  
    if (event.target && event.target.value && event.target.value === '---') {
      return;
    }
    
    // If this is a select or quicksearch control, end editing once a value 
    // has been selected
    if (this.props.type === 'quicksearch' 
      || (this.props.as && this.props.as === 'select')) {
        this.endEdit();
    }
  
    if (event.target) {
      const { value } = event.target;
      const charCode = value.charCodeAt(value.length - 1);

      // Don't trigger onChange events for text inputs when the last character
      // is an Enter or Tab keypress
      if (this.props.type === 'text' && (charCode === 13 || charCode === 0)) {
        return;
      }
    }
    
    this.lastEvent = event;
    this.props.onChange && this.props.onChange(event);
  }
  
  handleKeyPress(event) {
    if (this.props.as === 'textarea') return;
    if (event.charCode === 13 || event.charCode === 9) this.endEdit();
  }
  
  handleSearch(event) {
    const { value } = event.target;
    this.setState({ search: value });
  }
  
  startEdit() {
    this.setState({ ...this.state, edit: true });
  }

  endEdit() {
    this.setState({ ...this.state, edit: false });
    this.props.onEndEdit && this.props.onEndEdit(this.lastEvent);
  }
  
  render() {
    const { 
      __proto__, 
      children, 
      onChange, 
      type, 
      className,
      options: opts, 
      ...props } = this.props;
  
    const controlStyle = { minHeight: 0 };
  
    const editStyle = {
      display: 'inline-block',
      position: 'absolute',
      top: '-0.7em',
      zIndex: 1,
      padding: '0.5em',
      background: '#eee',
      borderRadius: '5px'
    };

    const nowrap = this.props.as === 'textarea' || 'wrap' in this.props ? false : true;
    
    const search = this.state.search && this.state.search.toLowerCase();
    const options = [ ];
    
    // Smart formatting of options parameter.
    if (this.props.options) {
    
      // Arrays of objects are assigned as-is and expected to contain 'key' and
      // 'value' parameters.  Arrays of singletons are mapped to an array
      // of objects where the singleton value is assigned to both 'key' and
      // 'value'.
      if (typeof this.props.options === 'object' && this.props.options.length) {
        if (typeof this.props.options[ 0 ] === 'object') {
          options.push(...this.props.options);
        } else {
          options.push(...this.props.options.map(key => ({ key, value: key })));
        }
        
      // Objects are mapped to an array of objects having 'key' and 'value' 
      // parameters mapped to the objects keys and values.  If an object value
      // is itself an object, we attempt to assign that child objects 'value'
      // parameter.  Otherwise, the parent object's key is assigned to both
      // 'key' and 'value'.
      } else if (typeof this.props.options ==='object') {
        Object.keys(this.props.options).map(key => {
          if (typeof this.props.options[ key ] === 'object') {
            if ('value' in this.props.options[ key ]) {
              return options.push({ 
                key, value: this.props.options[ key ].value 
              });
            } else {
              return options.push({ key, value: key });
            }
          } else {
            return options.push({ key, value: this.props.options[ key ] });          
          }
        });
      }
    }
    
    // Filter options list to include only matching search terms if provided
    const optionsShow = options.filter(option => 
      !search || !search.length
      || (option.value && option.value.toLowerCase().indexOf(search) > -1)
    );
    
    if (type === 'text') props.as = 'input';
    
    return (
      <span
        style={{
          position: 'relative',
          ...(nowrap && { whiteSpace: 'nowrap' }),
        }}
        className={className}
        key={props['name']}
      >
        <span style={ this.state.edit ? editStyle : { display: 'none' } }>
          { this.props.as && this.props.as === 'select' &&
            <span>
              { options.length && options.length > 10 &&
                <Form.Control
                  type="text"
                  size={ props.size || 'sm' }
                  htmlSize={ props.htmlSize }
                  className="quickedit-select-search"
                  style={ controlStyle }
                  onBlur={ this.handleBlur }
                  onChange={ this.handleSearch }
                  ref={ this.searchRef } />
              }
              <Form.Control 
                { ...props }
                className="quickedit-select"
                style={ controlStyle }
                onBlur={ this.handleBlur }
                onChange={ this.handleChange }
                ref={ this.editRef } >
                  <option>---</option>
                  { optionsShow.map(option =>
                    <option key={ option.key } value={ option.key }>
                      { option.value }
                    </option>
                  )}
              </Form.Control>
            </span>
          }
          
          { this.props.as && this.props.as !== 'select' &&
            <Form.Control 
              {...props}
              style={controlStyle}
              onBlur={this.endEdit}
              onChange={this.handleChange}
              onKeyPress={this.handleKeyPress}
              ref={this.editRef} />
          }
          
          { this.props.type && this.props.type === 'quicksearch' &&
            <QuickSearch 
              {...props} 
              style={controlStyle}
              onBlur={this.endEdit}
              onChange={this.handleChange}
              focus={this.editRef} />
          }
          
        </span>
        <span onClick={this.startEdit}>
          {this.props.children}
        </span>
      </span>
    );
  }
}

export { QuickEdit };
