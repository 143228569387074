import React, { useState, useEffect } from 'react';

const Pager = ({ activePage, pageCount, onSelect }) => {
  const [isMobile, setIsMobile] = useState(false);
  const mobileDisplay = 5; // Number of pages to display on mobile
  const desktopDisplay = 10; // Number of pages to display on desktop

  // Function to update the isMobile state based on window width
  const handleResize = () => {
    setIsMobile(window.innerWidth < 768); // Example breakpoint for mobile
  };

  useEffect(() => {
    // Set initial state
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const display = isMobile ? mobileDisplay : desktopDisplay;

  const pages = [];
  let begin = Math.max(1, activePage - Math.floor(display / 2));
  let end = Math.min(pageCount, activePage + Math.floor(display / 2));

  // Adjust the range if we're near the start or end
  if (end - begin + 1 < display) {
    if (begin === 1) {
      end = Math.min(pageCount, begin + display - 1);
    } else if (end === pageCount) {
      begin = Math.max(1, end - display + 1);
    }
  }

  for (let page = begin; page <= end; page++) {
    pages.push(page);
  }

  // Determine pagination size class based on device
  const paginationSizeClass = isMobile ? 'pagination-sm' : 'pagination-lg';

  return (
    <div className="pager-container">
      <nav aria-label="Page navigation" className="pager-nav">
        <ul className={`pagination ${paginationSizeClass} no-wrap`}>
          <li className={`page-item ${activePage === 1 ? 'disabled' : ''}`}>
            <button
              className="page-link"
              onClick={() => onSelect(1)}
              aria-label="First"
            >
              &laquo;
            </button>
          </li>
          <li className={`page-item ${activePage === 1 ? 'disabled' : ''}`}>
            <button
              className="page-link"
              onClick={() => onSelect(activePage - 1)}
              aria-label="Previous"
            >
              &lsaquo;
            </button>
          </li>
          {pages.map((page) => (
            <li
              key={page}
              className={`page-item ${page === activePage ? 'active' : ''}`}
            >
              <button className="page-link" onClick={() => onSelect(page)}>
                {page}
              </button>
            </li>
          ))}
          <li
            className={`page-item ${
              activePage === pageCount ? 'disabled' : ''
            }`}
          >
            <button
              className="page-link"
              onClick={() => onSelect(activePage + 1)}
              aria-label="Next"
            >
              &rsaquo;
            </button>
          </li>
          <li
            className={`page-item ${
              activePage === pageCount ? 'disabled' : ''
            }`}
          >
            <button
              className="page-link"
              onClick={() => onSelect(pageCount)}
              aria-label="Last"
            >
              &raquo;
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export { Pager };

