import React, { useState, useMemo, useEffect } from 'react';
import { Badge } from 'react-bootstrap';
import { FaFilter, FaSortUp, FaSortDown, FaTimes, FaTimesCircle } from 'react-icons/fa';

const ResultsFilter = ({
  filterDefinitions,
  onFilterChange,
  onSortChange
}) => {
  const [filters, setFilters] = useState({});
  const [sorts, setSorts] = useState([]);
  const [activeFilterCategory, setActiveFilterCategory] = useState(null);
  const [activeSortCategory, setActiveSortCategory] = useState(null);

  // Separate filter and sort categories based on their names
  const { filterCategories, sortCategories } = useMemo(() => {
    const filters = {};
    const sorts = {};
    Object.entries(filterDefinitions).forEach(([category, definition]) => {
      if (category.endsWith('Filter')) {
        filters[category] = definition;
      } else if (category.endsWith('Sort')) {
        sorts[category] = definition;
      }
    });
    return { filterCategories: filters, sortCategories: sorts };
  }, [filterDefinitions]);

  /*
  useEffect(() => {
    setFilters(initialFilters);
    setSorts(initialSort);
  }, [initialFilters, initialSort]);
  */

  useEffect(() => {
    console.log(filters);
    //onFilterChange(filters);
  }, [filters]);

  const toggleFilterCategory = (category) => {
    setActiveFilterCategory(prevCategory => (prevCategory === category ? null : category));
    setActiveSortCategory(null); // Close any active sort dropdown
  };

  const toggleSortCategory = (category) => {
    setActiveSortCategory(prevCategory => (prevCategory === category ? null : category));
    setActiveFilterCategory(null); // Close any active filter dropdown
  };

  const handleFilterSelect = (category, value, toggle = false) => {
    const { multiple, operator } = filterCategories[category];
    const currentValues = filters[category] || (multiple ? [] : '');
    let newValue;
    let filter;

    if (multiple) {
      if (currentValues.includes(value)) {
        newValue = currentValues.filter(v => v !== value);
        if (toggle) {
          newValue.push(value.startsWith('NOT ') ? value.slice(4) : `NOT ${value}`);
        }
      } else {
        newValue = [...currentValues, value];
      }
    } else {
      // For single selection, toggle between value, NOT value, and empty
      if (currentValues === value) {
        newValue = [`NOT ${value}`];
      } else {
        newValue = [value];
      }
    }

    // If multiple values, combine them into a single clause.  Negative clauses are always
    // comebined with AND.  Positive clauses are combined with the operator specified in the
    // filter definition.
    if (Array.isArray(newValue)) {
      const negativeClauses = newValue.filter(v => v.startsWith('NOT ')).join(' AND ');
      const positiveClauses = newValue.filter(v => !v.startsWith('NOT ')).join(` ${operator} `);

      filter = `
        ${negativeClauses ? `(${negativeClauses})` : ''}
        ${negativeClauses && positiveClauses ? ' AND ' : ''}
        ${positiveClauses ? `(${positiveClauses})` : ''}
      `.replace(/\n/g, ' ').replace(/\s+/g, ' ').trim();
    }

    setFilters(prevFilters => ({
      ...prevFilters,
      [category]: newValue
    }));

    onFilterChange(category, filter);
  };

  const removeFilter = (category, value) => {
    const { multiple, operator } = filterCategories[category];
    let newValue;

    if (multiple) {
      newValue = filters[category].filter(v => v !== value && v !== `NOT ${value}`);
    } else {
      newValue = [];
    }

    setFilters(prevFilters => ({
      ...prevFilters,
      [category]: newValue
    }));
    onFilterChange(category, newValue, operator);
    setActiveFilterCategory(null);
  };

  const handleSortSelect = (category, value) => {
    let newSort = '';
    const existingSort = sorts.find(sort => sort.startsWith(value));

    if (existingSort) {
      // Toggle sort direction
      const [field, direction] = existingSort.split(':');
      const newDirection = direction === 'desc' ? 'asc' : 'desc';
      newSort = `${field}:${newDirection}`;
    } else {
      // Set new sort with default ':desc'
      newSort = `${value}:desc`;
    }

    setSorts(newSort ? [newSort] : []);
    onSortChange(newSort ? [newSort] : []);
    setActiveSortCategory(null);
  };

  const removeSort = (field) => {
    const newSorts = sorts.filter(sort => !sort.startsWith(field));
    setSorts(newSorts);
    onSortChange(newSorts);
  };

  const renderSelectedFilters = (category) => {
    const selected = filters[category]?.sort();
    if (!selected) return null;

    const values = Array.isArray(selected) ? selected : [selected];

    return values.map(value => {
      const isNegated = value.startsWith('NOT ');
      const baseValue = isNegated ? value.slice(4) : value;
      const label = filterCategories[category]
        .options
        .find(opt => opt.value === baseValue)
        ?.label || baseValue;
      
      return (
        <Badge
          key={value}
          variant={isNegated ? 'danger' : 'primary'}
          className="mr-2 mb-1 clickable"
          onClick={(e) => {
            e.stopPropagation();
            handleFilterSelect(category, value, true);
          }}
        >
          <FaTimes
            className="mr-1"
            onClick={(e) => {
              e.stopPropagation();
              removeFilter(category, value);
            }}
          />
          <span className="pr-1">{label}</span>
        </Badge>
      );
    });
  };

  const renderSelectedSorts = (category) => {
    if (!sorts.length) return null;

    return sorts.map(sort => {
      const [field, direction] = sort.split(':');
      const label = sortCategories[category].options.find(opt => opt.value === field)?.label || field;
      return (
        <Badge
          key={sort}
          variant="primary" // Changed to primary
          className="mr-2 mb-1 clickable"
        >
          <FaTimes
            className="mr-1" 
            onClick={() => removeSort(field)}
          />
          <span onClick={(e) => {
            e.stopPropagation();
            handleSortSelect(field, field);
          }}>
            {label} {direction === 'asc' ? <FaSortUp /> : <FaSortDown />}
          </span>
        </Badge>
      );
    });
  };

  const renderFilterOptions = (category) => {
    const { options, multiple } = filterCategories[category];
    const selectedValues = filters[category] || (multiple ? [] : '');

    return (
      <div className="filter-options mt-2">
        {options.map(option => {

          // Check if the option is selected
          const isSelected = multiple
            ? selectedValues.includes(option.value)
            : selectedValues === option.value;

          // Check if the option is negated (NOT value)
          const isNegated = multiple
            ? selectedValues.includes(`NOT ${option.value}`)
            : selectedValues === `NOT ${option.value}`;

          // Get the selected value
          const selectedValue = isNegated ? `NOT ${option.value}` : option.value;

          // Set badge class based on selection state
          let badgeClass = 'badge-outline-primary';
          if (isSelected || isNegated) badgeClass = 'badge-primary';

          return (
            <span
              key={option.value}
              className={`badge ${badgeClass} mr-2`}
              style={{ cursor: 'pointer' }}
              onClick={() => handleFilterSelect(category, selectedValue)}
            >
              {option.label}
            </span>
          );
        })}
      </div>
    );
  };

  const renderSortOptions = (category) => {
    const { options } = sortCategories[category];

    return (
      <div className="sort-options mt-2">
        {options.map(option => {
          const currentSort = sorts.find(sort => sort.startsWith(option.value));
          const direction = currentSort ? currentSort.split(':')[1] : 'desc';
          const isSelected = Boolean(currentSort);
          const Icon = direction === 'asc' ? FaSortUp : FaSortDown;

          return (
            <span
              key={option.value}
              className={`badge ${isSelected ? 'badge-primary' : 'badge-outline-primary'} mr-2`}
              style={{ cursor: 'pointer' }}
              onClick={() => handleSortSelect(category, option.value)}
            >
              {option.label} <Icon />
            </span>
          );
        })}
      </div>
    );
  };

  return (
    <div className="mb-3">
      {/* Render filter category selectors */}
      {Object.entries(filterCategories).map(([category, { label }]) => (
        <span
          key={category}
          onClick={() => toggleFilterCategory(category)}
          className="small mr-4 text-muted clickable"
        >
          <FaFilter className="mr-1" /> {label}
          <span className="ml-2">{renderSelectedFilters(category)}</span>
        </span>
      ))}

      {/* Render sort category selectors */}
      {Object.entries(sortCategories).map(([category, { label }]) => (
        <span
          key={category}
          onClick={() => toggleSortCategory(category)}
          className="small mr-4 text-muted clickable"
        >
          <FaSortUp className="mr-1" /> {label}
          <span className="ml-2">{renderSelectedSorts(category)}</span>
        </span>
      ))}

      {/* Render active filter options */}
      {activeFilterCategory && (
        <div className="mt-2">
          {renderFilterOptions(activeFilterCategory)}
        </div>
      )}

      {/* Render active sort options */}
      {activeSortCategory && (
        <div className="mt-2">
          {renderSortOptions(activeSortCategory)}
        </div>
      )}
    </div>
  );
};

export default ResultsFilter;
