export const getProductCost = (product, type) => {
    if (product.cost?.[type]) {
        return (product.cost[type]).toFixed(2);
    }
}
  
export const getProductPrice = (product, type) => {
    if (product.price?.[type]) {
      return (product.price[type]).toFixed(2);
    }
}
  
export const getProductProfit = (product, type) => {
    const price = getProductPrice(product, type);
    const cost = getProductCost(product, type);

    if (price === 'NA' || cost === 'NA') {
      return;
    }

    return (price - cost).toFixed(2);
}
  
export const getProductFloor = (product, type) => {
    if (product.reprice?.[type]?.floor) {
      return parseFloat(product.reprice[type].floor).toFixed(2);
    }

    if (product.cost?.[type]) {
      return (product.cost[type] * 1.5 + 10).toFixed(2);
    }
}

export const getProductCeiling = (product, type) => {
    if (product.reprice?.[type]?.ceiling) {
      return parseFloat(product.reprice[type].ceiling).toFixed(2);
    }

    if (product.price?.[type]) {
      return (product.price[type] * 1.25).toFixed(2);
    }

    if (product.cost?.[type]) {
      return (product.cost[type] * 4.00).toFixed(2);
    }  
}
  
export const getListingFloor = (listing, product) => {
    if (listing.reprice?.floor) {
      return parseFloat(listing.reprice.floor).toFixed(2);
    }

    return getProductFloor(product, listing.condition);
};

export const getListingCeiling = (listing, product) => {
    if (listing.reprice?.ceiling) {
      return parseFloat(listing.reprice.ceiling).toFixed(2);
    }

    return getProductCeiling(product, listing.condition);
};

export const getListingPrice = (listing) => {
    const price = listing.reprice?.price || 
                  listing.override?.price || 
                  listing.pricing?.myOffer?.price || 
                  listing.channelPrice || 
                  listing.price;

    if ( price) {
        return parseFloat(price).toFixed(2);
    }
};
  
  
