import React from 'react';
import { getChannelIcon, getChannelLink, ResultsComponent } from './AllThing';
import { Badge, Container, Row, Col } from 'react-bootstrap';
import { Pager } from './Pager';
import { ProductRow } from './ProductRow';
import { ProductEdit } from './ProductEdit';
import { ProductTagsEdit } from './ProductTagsEdit';
import { ListingEdit } from './ListingEdit';
import { StockCommitEdit } from './StockCommitEdit';
import ResultsFilter from './ResultsFilter';
import BatchEdit from './BatchEdit';
import ClipboardCopy from './ClipboardCopy';
import {
  productStatusFilter,
  productChannelFilter,
  productStockFilter,
  productPricingFilter,
  productSort
} from './filterDefinitions';

class Products extends ResultsComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      filters: {
        archived: '',
        channels: [],
        fgStock: ''
      },
      defaultChannel: 'Amazon US',
      defaultCondition: 'new',
      bulkEdit: false  // Add this line
    };

    this.editProduct = this.editProduct.bind(this);
    this.editTags = this.editTags.bind(this);
    this.editListing = this.editListing.bind(this);
    this.editStock = this.editStock.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.toggleBulkEdit = this.toggleBulkEdit.bind(this);  // Add this line
  }

  // Batch operation methods remain unchanged
  batchAddTag(tag, data, path) {
    data.tags = data.tags || [];
    
    if (data.tags.indexOf(tag) < 0) {
      data.tags.push(tag);

      return { data: { [`${path}/tags`]: data.tags } }; 
    }
  }
  
  batchRemoveTag(tag, data, path) {
    data.tags = data.tags || [];
    const tagIndex = data.tags.indexOf(tag);

    if (tagIndex >= 0) {
      data.tags.splice(tagIndex, 1);

      return { data: { [`${path}/tags`]: data.tags } }; 
    }
  }
  
  batchSetVendor(vendor, data, path) {
    return {
      data: { [`${path}/vendor`]: vendor }
    };
  }

  // Modal methods remain unchanged
  editProduct(product) {
    const modalContent = (
      <ProductEdit 
        row={ product } 
        search={ this.props.search }
        index={ this.props.index }
        editStock={ this.editStock }
        hideModal={ this.hideModal }
        allowDelete="true"
        setModalButtons={this.setModalButtons} />
    );

    const modalTitle = (
      <span>
        {product ? 'Edit' : 'Add'} Product 
        {product ? (
          <ClipboardCopy copy={product.key} />
        ) : 'New'}
      </span>
    );
    
    this.showModal(modalTitle, modalContent);
  }
    
  editStock(row) { 
    const modalContent = (
      <StockCommitEdit 
        title={ row.title }
        search={ this.props.search }
        hideModal={ this.hideModal }
        setModalButtons={ this.setModalButtons }
        product={ row } />
    );
    
    this.showModal(`Edit Stock`, modalContent);
  }
  
  editTags(row) {
    const modalContent = (
      <ProductTagsEdit 
        row={row} 
        search={this.props.search}
        index={this.props.index}
        hideModal={ this.hideModal }
        setModalButtons={this.setModalButtons} />
    );
    
    this.showModal(`Edit Product Tags`, modalContent);
  }

  editListing(row, product = {}, listings = []) {
    if (!row || !Object.keys(row).length) {
      row = {
        model: product.model,
        product: product.key
      };
    }
        
    const modalTitle = (
      <span>
        <a href={getChannelLink(row)} target="_blank" rel="noopener noreferrer">
          {getChannelIcon(row.channel)}
        </a>&nbsp;
        {row.key ? 'Edit' : 'Add'} Listing {row.key ? (
          <ClipboardCopy copy={row.key} />
        ) : '(New)'}
      </span>
    );
    
    const modalContent = (
      <ListingEdit 
        row={row}
        product={product}
        listings={listings}
        search={this.props.search}
        index="listing"
        hideModal={this.hideModal}
        allowDelete="true"
        setModalButtons={this.setModalButtons} />
    );
    
    this.showModal(modalTitle, modalContent);
  }

  handleFilterChange(category, value) {
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        [category]: value
      }
    }));
  }

  toggleBulkEdit() {
    this.setState(prevState => ({
      bulkEdit: !prevState.bulkEdit
    }));
  }

  render() {
    let productData = null;

    if (this.search.location === window.location.href) {
      productData = this.state.results.map(row =>
        <ProductRow 
          key={row.key} 
          row={row} 
          search={this.search}
          index={this.index}
          editProduct={this.editProduct}
          editTags={this.editTags}
          editListing={this.editListing}
          editStock={ this.editStock }
          handleCheck={this.handleCheck}
          isChecked={this.isChecked}
          showImages={this.showImages}
          showModal={this.showModal}
          defaultChannel={this.state.defaultChannel}
          defaultCondition={this.state.defaultCondition}
          bulkEdit={this.state.bulkEdit} /> 
      );
    } else {
      productData = <Col xs={12}>Loading Data ...</Col>;
    }

    return (
      <div>
        { this.renderModal() }
        <Container fluid>
          <Row>
            <Col>
              <ResultsFilter 
                filterDefinitions={{
                  productStatusFilter,
                  productChannelFilter,
                  productStockFilter,
                  productPricingFilter,
                  productSort
                }}
                onFilterChange={this.setFilter}
                onSortChange={this.setSort}
              />
            </Col>
          </Row>
          <Row>
            {this.countChecked() > 0 && (
              <Col sm={3} md={2}>
                <BatchEdit
                  checkedCount={this.countChecked()}
                  totalCount={this.search.count}
                  onCheckAll={this.checkAll}
                  getBatchDataPaths={this.getBatchDataPaths}
                />
              </Col>
            )}
            <Col sm={this.countChecked() > 0 ? 9 : 12} md={this.countChecked() > 0 ? 10 : 12}>
              <Row>
                <Col>
                  <Badge 
                    pill 
                    variant={this.state.bulkEdit ? "primary" : "secondary"} 
                    className="mr-2 clickable " 
                    onClick={this.toggleBulkEdit}
                  >
                    Bulk Edit
                  </Badge>
                  {this.state.bulkEdit && (
                    <Badge pill variant="secondary" className="mr-2 clickable" onClick={() => this.handleCheck('all')}>
                      Select All
                    </Badge>
                  )}
                  <Badge pill variant="secondary" className="clickable" onClick={() => this.editProduct()}>
                    Add New Product
                  </Badge>
                </Col>
              </Row>
              {productData}
              <Pager
                pageCount={this.search.pages} 
                activePage={this.search.page} 
                onSelect={this.search.setPage} />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export { Products };