import React, { Component } from 'react';
import firebase from './fire';
import { v4 as uuidv4 } from 'uuid';

// Create standalone saveFile function
const saveFile = (file, onUpload) => {
  const filename = uuidv4();
  const filepath = `listing/image/${filename}`;
  
  return firebase.storage()
    .ref()
    .child(filepath)
    .put(file)
    .then(snap => 
      snap.ref
        .getDownloadURL()
        .then(url => onUpload(url))
    )
    .catch(err => console.log('Upload Error:', err));
};

class Upload extends Component {
  constructor(props) {
    super(props);    
        
    this.state = {
    };
    
    this.editRef = React.createRef();

    // Function scope bindings
    this.handleSaveFile = this.handleSaveFile.bind(this);
    this.upload = this.upload.bind(this);
  }
  
  // Autofocus form control when in edit mode
  componentDidUpdate() {
    if (this.state.edit) this.editRef.current.focus();
  }
  
  // Rename component method and use standalone function
  handleSaveFile(file) {
    return saveFile(file, this.props.onUpload);
  }

  upload(e) {
    const files = e.target.files;
    
    if (!files || !files.length) return;
    
    console.log(files);

    for (let i = 0; i < files.length; i++) {
      this.handleSaveFile(files[i]);  // Update method name
    }
  }
      
  render() {
    return (
      <span>
        <input 
          type="file" 
          id="upload" 
          ref={ this.editRef } 
          style={{ display: 'none' }} 
          onChange={ this.upload } />
        <span onClick={ () => this.editRef.current.click() }>
          {this.props.children}
        </span>
      </span>
        
    );
  }
}

export { Upload, saveFile };
