import React from 'react';
import { Badge, Col, Image, Row } from 'react-bootstrap';
import { FaImage, FaHashtag } from 'react-icons/fa';
import { StockWarehouse } from './StockWarehouse';


const SearchProductResults = props => {
  const row = props.row;
  const rowTitle = (row.title || '').length > 50 ? (row.title || '').slice(0, 50) + '...' : (row.title || '');

  return (
    <Row className="search-product-row">
      <Col sm={ 2 }>
        { row.image &&
          <div className="thumbnail">
            <Image 
              fluid 
              src={row.image && row.image[0]} 
              onClick={() => this.showImages(row.image)} />
          </div>
        }
        { !row.image && 
          <h1 className="text-center"><FaImage /></h1>
        }
      </Col>
      <Col sm={ 10 }>
        <div>
          <span className="product">{row.vendor} {row.model}</span>
        </div>
        <div>
          { row.partNumber &&
            <small className="small mr-2">
              <FaHashtag /> {row.partNumber}
            </small>  
          }
          <small className="stock text-muted">
            [ <StockWarehouse stock={ row.stock } compact /> ]
          </small>

          { !Object.keys(row.listing || { }).length &&
            <Badge variant="danger" className="badge-danger-inverted">
              No Listings
            </Badge>          
          }
          
        </div>
        <div>
          { rowTitle.match(/^DUPLICATE/) &&
            <small className="text-danger">{rowTitle}</small>
          }
          { !rowTitle.match(/^DUPLICATE/) &&          
            <small className="text-muted">{rowTitle}</small>
          }
        </div>
      </Col>
    </Row>
  );
}

export { SearchProductResults };
